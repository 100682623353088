.xterm {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.xterm.focus, .xterm:focus {
  outline: none;
}

.xterm .xterm-helpers {
  z-index: 5;
  position: absolute;
  top: 0;
}

.xterm .xterm-helper-textarea {
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -5;
  white-space: nowrap;
  resize: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: -9999em;
  overflow: hidden;
}

.xterm .composition-view {
  color: #fff;
  white-space: nowrap;
  z-index: 1;
  background: #000;
  display: none;
  position: absolute;
}

.xterm .composition-view.active {
  display: block;
}

.xterm .xterm-viewport {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
}

.xterm .xterm-screen {
  position: relative;
}

.xterm .xterm-screen canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.xterm .xterm-scroll-area {
  visibility: hidden;
}

.xterm-char-measure-element {
  visibility: hidden;
  line-height: normal;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -9999em;
}

.xterm {
  cursor: text;
}

.xterm.enable-mouse-events {
  cursor: default;
}

.xterm.xterm-cursor-pointer {
  cursor: pointer;
}

.xterm.column-select.focus {
  cursor: crosshair;
}

.xterm .xterm-accessibility, .xterm .xterm-message {
  z-index: 10;
  color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.xterm .live-region {
  width: 1px;
  height: 1px;
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.xterm-dim {
  opacity: .5;
}

.xterm-underline {
  text-decoration: underline;
}

.xterm-strikethrough {
  text-decoration: line-through;
}

/*# sourceMappingURL=index.619b4a53.css.map */
